import React from "react";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import DribbleLoader from "../components/UI/DribbleLoader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WaitForUs = () => {
  return (
    <div className="vh-100">
      <img
        className="w-100 h-100 object-fit-cover d-block"
        src={require("../assets/background.jpg")}
        alt=""
      />
      <div
        style={{ backgroundColor: "rgba(0,0, 0, 0.8)" }}
        className="position-fixed top-0 start-0 w-100 h-100"
      >
        <div
          style={{ maxWidth: "700px" }}
          className="mx-auto d-flex px-2 flex-column justify-content-between h-100 gap-3"
        >
          <div>
            <div className="d-flex gap-3 align-items-center justify-content-center justify-content-sm-between">
              <div
                style={{ width: "35%", minWidth: "180px", maxHeight: "180px" }}
              >
                <img
                  className="d-block w-100 object-fit-cover"
                  src={require("../assets/Logo EN.png")}
                  alt=""
                />
              </div>
              <div
                className="d-none d-sm-block"
                style={{ width: "35%", minWidth: "180px", maxHeight: "180px" }}
              >
                <img
                  className="d-block w-100 object-fit-cover"
                  src={require("../assets/Logo AR.png")}
                  alt=""
                />
              </div>
            </div>
            <div
              className="mx-auto"
              style={{ minWidth: "200px", maxWidth: "300px" }}
            >
              <img
                className="d-block w-100"
                src={require("../assets/يأتى قريبا.png")}
                alt=""
              />
              <img
                className="d-block w-100"
                src={require("../assets/Coming soon.png")}
                alt=""
              />
            </div>
            <div className="text-center">
              <DribbleLoader />
            </div>
            <h5 className="text-center text-white">Contact us</h5>
            <div className="d-flex gap-3 my-2 justify-content-center">
              <a
                href="tel:+01093375424"
                target="_blank"
                rel="noreferrer"
                style={{ minWidth: "55px", width: "55px" }}
                className="bg-transparent border-0 link-hover transition-main"
              >
                <img
                  className="d-block w-100"
                  src={require("../assets/telephone.png")}
                  alt=""
                />
              </a>

              <a
                href="https://www.google.com/maps/place/%D9%81%D9%88%D9%83%D8%B3+%D9%83%D9%8A%D9%85+%D9%84%D9%83%D9%8A%D9%85%D8%A7%D9%88%D9%8A%D8%A7%D8%AA+%D8%A7%D9%84%D8%A8%D9%86%D8%A7%D8%A1+%D9%88+%D8%A7%D9%84%D8%AF%D9%8A%D9%83%D9%88%D8%B1%D8%A7%D8%AA%E2%80%AD/@31.4259527,31.6774729,17z/data=!3m1!4b1!4m6!3m5!1s0x14f9e3a8b700d91f:0x97c3ff818b268b9b!8m2!3d31.4259527!4d31.6800478!16s%2Fg%2F11j20gnj5c?entry=ttu"
                target="_blank"
                rel="noreferrer"
                style={{ minWidth: "55px", width: "55px" }}
                className="bg-transparent border-0 link-hover transition-main"
              >
                <img
                  className="d-block w-100"
                  src={require("../assets/gps.png")}
                  alt=""
                />
              </a>
              <a
                href="https://wa.me/+201014088820"
                target="_blank"
                rel="noreferrer"
                style={{ minWidth: "55px", width: "55px" }}
                className="bg-transparent border-0 link-hover transition-main"
              >
                <img
                  className="d-block w-100"
                  src={require("../assets/whatsapp.png")}
                  alt=""
                />
              </a>
              <a
                href="mailto:foxchem2@gmail.com"
                target="_blank"
                rel="noreferrer"
                style={{ minWidth: "55px", width: "55px" }}
                className="bg-transparent border-0 link-hover transition-main"
              >
                <img
                  className="d-block w-100"
                  src={require("../assets/envelope.png")}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-2 pb-4 align-items-center text-white">
            <div className="d-flex align-items-center gap-2 flex-grow-1">
              <FontAwesomeIcon className="fs-4" icon={faPhone} />
              <p className="mb-0">01014088820 - 01093375424</p>
            </div>
            <div className="d-flex align-items-center gap-2 flex-grow-1">
              <FontAwesomeIcon className="fs-4" icon={faEnvelope} />
              <p className="mb-0">foxchem2@gmail.com</p>
            </div>
            <div className="d-flex align-items-center gap-2 flex-grow-1">
              <FontAwesomeIcon className="fs-4" icon={faLocationDot} />
              <p className="mb-0">
                دمياط الجديدة - أمام مديرية الأمن - شركة فوكس كيم
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitForUs;
