import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import WaitForUs from "./components/WaitForUs";

function App() {
  return (
    <div className="App">
      <WaitForUs />
    </div>
  );
}

export default App;
