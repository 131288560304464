import React from "react";
import classes from "./DribbleLoader.module.css";

const DribbleLoader = ({ className }) => {
  return (
    <div className={`${classes["lds-ripple"]} ${className}`}>
      <div></div>
      <div></div>
    </div>
  );
};

export default DribbleLoader;
